/* NOTIFICATION Badge CSS */
.notification--badge {
  position: relative;
}
.notification--badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -15px;
  font-size: 0.7em;
  background: red;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
}