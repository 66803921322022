.toolbar {
  position: fixed;
  z-index: 1039;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  height: 60px;
  box-shadow: 0px 0px 5px 0px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 10px;
}
.vertical {
  border-left: 3px solid #585858;
  height: 45px;
  /* margin-left: 2.2rem; */
  margin-right: 0.5rem;
}

.my_boookings_text {
  color: #585858;
}

.no__badge__color {
  text-decoration: none;
  color: #585858 !important;
}

.toolbar__logo {
  margin-right: 3.5rem;
  width: 120px !important;
}

.toolbar__logo a {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-decoration: none !important;
  font-size: 30px;
  color: var(--theme-yellow);
}

.toolbar__logo :hover {
  color: var(--theme-yellow);
}
.toolbar__logo a :hover {
  color: var(--theme-yellow);
}

.logoName {
  font-weight: 700;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-decoration: none !important;
  font-size: 30px;
  color: var(--theme-yellow);
}

.logoName :hover {
  color: var(--theme-yellow);
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
  display: flex;
  font-size: 1.1rem;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
  font-weight: 400;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.toolbar_navigation-items a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none !important;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #666666;
}

.selected {
  font-weight: bolder;
}

.homePage__style {
  box-shadow: none;
  background: transparent;
  position: relative;
}

@media (max-width: 768px) {
  .toolbar_navigation-items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}

@media (min-width: 769px) {
  .vertical {
    display: none;
  }
  .my_boookings_text {
    display: none;
  }
}
