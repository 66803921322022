.error__message {
  font-size: 12px;
  color: red;
}

.text__switch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 15;
  color: #fff;
  padding-right: 2;
}

.font__size__20 {
  font-size: 1.25rem;
}
