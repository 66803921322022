.font__size__20 {
  font-size: 1.25rem;
}

.floating__btn__container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.floating__btn__border {
  /*border: 2px solid black;*/
}

.floating__btn {
  color: black;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  background-color: #ffca00;
  box-shadow: 4px 7px 5px 0px rgba(0, 0, 0, 0.75), inset 0 0 0 0 #e0b000;
  position: relative;
  overflow: hidden;
}

.floating__btn:hover {
  box-shadow: 4px 7px 5px 0px rgba(0, 0, 0, 0.75), inset 0 0 0 50px #e0b000;
}

.overlay {
  position: absolute;

  background: #fff;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0.5;
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    height: 0px;
    width: 0px;
    opacity: 0.5;
  }
  100% {
    height: 200px;
    width: 200px;
    opacity: 0.5;
  }
}
