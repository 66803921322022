/* HOMEPAGE LANDING IMAGE */
.homepage--landing--img {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  max-height: 410px;
  /* max-height: 320px; */
  background-position: center center;
}

@media screen and (max-width: 1050px) {
  .homepage--landing--img {
    object-fit: cover;
    max-height: 500px;
  }
}
@media screen and (max-width: 768px) {
  .homepage--landing--img {
    object-fit: fill;
    height: 100vh;
    max-height: 195px;
  }
}
