.font__size__20 {
  font-size: 1.25rem;
}
.font__size__16 {
  font-size: 1rem;
}
.font__size__12 {
  font-size: 0.75rem;
}

.light__grey__color {
  color: var(--light-grey);
}
.dark__grey__color {
  color: var(--dark-grey);
}

.Coupon {
  cursor: pointer;
}

.CouponName {
  font-weight: bold;
}

.CouponCode {
  padding: 3px;
  color: #ffc107;
  border: 1px solid #ffc107;
}

.CouponError {
  color: red;
}

.PromoCodeSuccessful {
  margin: 0px;
  width: 100%;
  border: 1px solid green;
  font-size: 12px;
  color: #2c3e50;
}

.PromoCodeFailed {
  margin: 0px;
  width: 100%;
  border: 1px solid red;
  font-size: 12px;
  color: #2c3e50;
}
