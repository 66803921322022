.FONT__SIZE__24 {
  font-size: 1.5rem;
}
.FONT__SIZE__20 {
  font-size: 1.25rem;
}
.FONT__SIZE__18 {
  font-size: 1.125rem;
}

.FONT__SIZE__16 {
  font-size: 1rem;
}
.FONT__SIZE__14 {
  font-size: 0.875rem;
}

.FONT__SIZE__12 {
  font-size: 0.75rem;
}

.FONT__SIZE__10 {
  font-size: 0.625rem;
}

.FONT__SIZE__5 {
  font-size: 0.313rem;
}
