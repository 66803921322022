.visibility__hidden {
  height: 0;
  width: 0;
}

.fake__checkbox__img::after {
  content: '';
  display: inline-block;
  padding: 0px;
  height: 20px;
  width: 20px;
  background: none;
  cursor: pointer;
  border: 3px solid gray;
}

.fake_radio_img::after {
  content: '';
  display: inline-block;
  padding: 0px;
  height: 20px;
  width: 20px;
  background: none;
  cursor: pointer;
  border: 3px solid gray;
  border-radius: 50%;
}

.fake__checkbox__img:checked::after {
  background: url(https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/blue_check.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 20px;
  cursor: pointer;
}

.fake_radio_img:checked::after {
  background: url(https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/blue_dot.jpeg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  cursor: pointer;
}

.fake_radio_img:disabled:checked::after {
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
}
