.shimmer__loader {
  background-color: #ecf0f1;
  width: 100vw !important;
  height: 30px !important;
  border-radius: 4px;
  animation: progress 1.2s ease-in-out infinite;
}

@keyframes progress {
  0% {
    background-color: #ecf0f1;
  }
  50% {
    background-color: #e0e5e9;
  }
  100% {
    background-color: #ecf0f1;
  }
}
