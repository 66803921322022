.grey__tick {
  height: 1.25rem;
  width: 1.25rem;
}

.font__size__20 {
  font-size: 1.25rem;
}
.font__size__16 {
  font-size: 1rem;
}
.font__size__12 {
  font-size: 0.75rem;
}

.light__grey__color {
  color: var(--light-grey);
}
.dark__grey__color {
  color: var(--dark-grey);
}

.ApplyPromoCode {
  cursor: pointer;
}

.Pro {
  color: #fff;
  font-size: 15px;
  background-color: #8a2be2;
  display: inline-block;
  padding: 6px;
  border-radius: 10px;
  margin: 10px 0px 0px 5px;
  height: 35px;
}

.partition{
  border-left:solid 0.5px lightgrey;
}

/* @media screen and (max-width: 390px){
  .partition{
    border-left: none;
    margin-top: 3rem;
  }
} */