/* ensure all pages have Bootstrap CSS */
@import '~bootstrap/dist/css/bootstrap.min.css';

:root {
  --theme-yellow: #f0c600;
  --dark-golden: #855e22;
  --light-golden: #8c7500;

  --dark-grey: #666666;
  --light-grey: #616161;

  --font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  /* --text-color: #124a63;
  --text-color-secondary: #b3c5cd;

  --primary-color: #21b6b7; */

  /* --background-color: #f9f9f9;
  --background-color-dark: #eef3f6;
  --background-color-light: white; */

  /* --font-family: 'Poppins', sans-serif;
  --box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); */
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Makes React Bootstrap Modal's height 100% always irrespective of the content inside */
.modal-dialog-wm {
  /* height: 100%;
  width: 100%; */
  min-height: 200px;
}

.custom__backdrop__zindex {
  z-index: 1051;
}

/* Makes Google dropdown visible above modal */
.pac-container {
  z-index: 1051 !important;
}

/* for step wizard */
.rsw_2f {
  display: none;
}

.rsw_3G {
  display: block;
}

._3AWTh {
  z-index: 1045 !important;
}

/* Rounds the cook profile photo */
.homepage__cook__profile__photo {
  border-radius: 50%;
}

.cursor__pointer {
  cursor: pointer;
}

.TEXT_DECORATION_NONE {
  text-decoration: none !important;
}

.modal-dialog-wm {
  margin: 0px;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.modal-dialog-wm .modal-content {
  max-height: 100% !important;
  max-width: 100% !important;
}

.resizeForMobile {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .resizeForMobile {
    height: 80px !important;
    width: 90px !important;
  }
}

@media screen and (max-width: 600px) {
  .resizeForMobile {
    height: 50px !important;
    width: 60px !important;
  }
}

@media screen and (max-width: 360px) {
  .resizeForMobile {
    height: 50px !important;
    width: 60px !important;
  }
}

@media screen and (max-width: 320px) {
  .resizeForMobile {
    height: 50px !important;
    width: 50px !important;
  }
}

.resizeforMobile {
  height: 110px !important;
}

@media screen and (max-width: 768px) {
  .resizeforMobile {
    height: 80px !important;
  }
}

@media screen and (max-width: 600px) {
  .resizeforMobile {
    height: 50px !important;
  }
}

.breadcrumb-ocl {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  /* background-color: red; */
  border-radius: 0.25rem;
  padding-left: 2rem;
  z-index: 3;
  position: relative;
  font-size: 13px;
}

/* .breadcrumb-item-ocl a {
  color: white;
} */

.breadcrumb-item-ocl-active {
  color: rgb(202, 201, 173);
  padding-left: 0.5rem;
}

.breadcrumb-item-ocl-active::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}
