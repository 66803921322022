.font__size__16 {
  font-size: 1rem;
}
.exit__alert__cta {
  margin: 5px;
  border: 1px solid black;
}

.font__size__20 {
  font-size: 1.25rem;
}

.dark__grey__color {
  color: var(--dark-grey);
}

.cursor__pointer {
  cursor: pointer;
}
