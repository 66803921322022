.DARK__GOLDEN__COLOR {
  color: var(--dark-golden);
}

.DARK__GREY__COLOR {
  color: var(--dark-grey);
}

.LIGHT__GREY__COLOR {
  color: var(--light-grey);
}
