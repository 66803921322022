.font__size__16 {
  font-size: 1rem;
}

.red__color {
  color: red;
}

.cta__container {
  display: flex;
  justify-content: center;
}

.exit__alert__cta {
  margin: 5px;
  border: 1px solid black;
}
