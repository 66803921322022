.grey__tick {
  height: 1.25rem;
  width: 1.25rem;
}

.font__size__20 {
  font-size: 1.25rem;
}
.font__size__16 {
  font-size: 1rem;
}

.font__size__12 {
  font-size: 0.75rem;
}

.font__size__11 {
  font-size: 0.688rem;
}

.light__grey__color {
  color: var(--light-grey);
}
.dark__grey__color {
  color: var(--dark-grey);
}

.ApplyPromoCode {
  cursor: pointer;
}
