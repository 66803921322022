.font__size__12 {
  font-size: 0.75rem;
}

.font__size__14 {
  font-size: 0.875rem;
}

.font__size__16 {
  font-size: 1rem;
}

.dark__grey__color {
  color: var(--dark-grey);
}

.light__grey__color {
  color: var(--light-grey);
}

.veg__nonveg__icon {
  height: 14px;
  position: relative;
  bottom: 2px;
}

.dish__name {
  position: relative;
  font-size: 14px;
  bottom: 4px;
}

.ingredients__oven_required {
  position: relative;
  bottom: 9px;
  font-size: 10px;
}

.served_with {
  font-size: 10px;
  position: relative;
  bottom: 7px;
}

.dish__img {
  width: 70px;
  height: 40px;
}

@media (min-width: 576px) {
  .MobileOnly {
    display: none;
  }
}

/* MEDIA QUERIES */

/* @media screen and (max-width: 768px) {
  .dish__img {
    height: 80px !important;
    width: 90px !important;
  }
}

@media screen and (max-width: 600px) {
  .dish__img {
    height: 50px !important;
    width: 60px !important;
  }
}

@media screen and (max-width: 360px) {
  .dish__img {
    height: 50px !important;
    width: 60px !important;
  }
}

@media screen and (max-width: 320px) {
  .dish__img {
    height: 50px !important;
    width: 50px !important;
  }
} */

.visibility__hidden {
  height: 0;
  width: 0;
}
.fake__checkbox__img::after {
  content: '';
  display: inline-block;
  padding: 0px;
  height: 17px;
  width: 17px;
  background: none;
  cursor: pointer;
  border: 3px solid gray;
}
.fake_radio_img::after {
  content: '';
  display: inline-block;
  padding: 0px;
  height: 17px;
  width: 17px;
  background: none;
  cursor: pointer;
  border: 3px solid gray;
  border-radius: 50%;
}
.fake__checkbox__img:checked::after {
  background: url(https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/blue_check.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 17px 17px;
  cursor: pointer;
}
.fake_radio_img:checked::after {
  background: url(https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/blue_dot.jpeg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  cursor: pointer;
}
.fake_radio_img:disabled:checked::after {
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
}