/* RADIO BUTTON STYLING */
.radio__button__container input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
  display: inline-block !important;
}

.radio__button__container input[type='checkbox'] {
  opacity: 0;
  position: fixed;
  width: 0;
  display: inline-block !important;
}

.radio__button__container label {
  display: inline-block;
  background-color: white;
  padding: 4px 8px;
  /* font-size: 16px; */
  border: 0.5px solid var(--light-grey);
  border-radius: 5px;
  color: var(--light-grey);
  margin-right: 0.5rem;
}

.radio__button__container label:hover {
  cursor: pointer;
}

.radio__button__container input[type='radio']:checked + label {
  border: 2px solid black;
  color: white;
  background-color: black;
  display: inline-block !important;
}

.radio__button__container input[type='radio']:disabled + label {
  color: lightgray;
}

.radio__button__container input[type='checkbox']:checked + label {
  border: 2px solid black;
  color: white;
  background-color: black;
  display: inline-block !important;
}
/* ----------------------- */

@media (min-width: 520px) {
  .radio__button__container label:hover {
    cursor: pointer;
  }
  .radio__button__container input:enabled + label:hover {
    background-color: #dcdcdc;
  }

  .radio__button__container input[type='radio']:checked + label:hover {
    background-color: black;
  }

  .radio__button__container input[type='checkbox']:checked + label:hover {
    background-color: black;
  }

  .radio__button__container input:disabled + label:hover {
    cursor: not-allowed;
  }
}
