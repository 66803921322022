.cursor__pointer {
  cursor: pointer;
}
.error__message {
  font-size: 12px;
  color: red;
}
.font__size__14 {
  font-size: 0.8rem;
}

.font__size__16 {
  font-size: 1rem;
}

.font__size__20 {
  font-size: 1.25rem;
}

.dark__grey__color {
  /* color: var(--dark-grey); */
}

.light__grey__color {
  color: var(--light-grey);
}

@media screen and (max-width: 968px) {
  .grey__tick {
    height: 0.8rem;
    width: 0.8rem;
  }
  .text {
    margin-left: 0px !important;
    font-size: 12px;
    padding-left: 0px !important;
  }
  .light__grey__color {
    font-size: 14px;
    color: var(--light-grey);
  }
}
@media screen and (max-width: 768px) {
  .text {
    margin-left: 0px !important;
    font-size: 10px;
    padding-left: 0px !important;
  }
  .light__grey__color {
    font-size: 12px;
    color: var(--light-grey);
  }
  .dark__grey__color {
    font-size: 16px;
  }
}
