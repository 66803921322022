.fake__checkbox__input {
  visibility: hidden;
}

.checkbox__img {
  display: inline-block;
  padding: 0px;
  height: 20px;
  width: 20px;
  background: none;
  cursor: pointer;
  /* border: 1px solid lightgray; */
  border: 3px solid grey;
}

.checkbox__img__filled {
  background: url(https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/blue_check.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 20px;
  cursor: pointer;
}
